.editor_h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
}

.editor_h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
}

.editor_h3 {
  font-size: 12px;
  margin: 0;
}

.editor_image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor_link {
  color: #475cff;
  text-decoration: none;
}

.editor_link:hover {
  text-decoration: underline;
}

.editor_listItem {
  margin: 0 32px;
}

.editor_nestedListItem {
  list-style-type: none;
}

.editor_nestedListItem:before,
.editor_nestedListItem:after {
  display: none;
}

.editor_ol1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}

.editor_ol2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-alpha;
  list-style-position: inside;
}

.editor_ol3 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-alpha;
  list-style-position: inside;
}

.editor_ol4 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-roman;
  list-style-position: inside;
}

.editor_ol5 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-roman;
  list-style-position: inside;
}

.editor_ul1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: disc;
  list-style-position: inside;
}

.editor_ul2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: circle;
  list-style-position: inside;
}

.editor_ltr {
  text-align: left;
}

.editor_paragraph {
  margin: 0;
  position: relative;
}

.editor_table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  width: calc(100% - 25px);
  margin: 30px 0;
}

.editor_tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}

.editor_tableAddColumns:after {
  /* background-image: url(../images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.editor_tableAddColumns:hover {
  background-color: #c9dbf0;
}

.editor_tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}

.editor_tableAddRows:after {
  /* background-image: url(../images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.editor_tableAddRows:hover {
  background-color: #c9dbf0;
}

.editor_tableCell {
  border: 1px solid #bbb;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  cursor: default;
  outline: none;
}

.editor_tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}

.editor_tableCellActionButton:hover {
  background-color: #ddd;
}

.editor_tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}

.editor_tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.editor_tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}

.editor_tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}

.editor_tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}

.editor_tableCellSelected {
  background-color: #c9dbf0;
}

.editor_tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}

.editor_tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}

.editor_tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}

.editor_textBold {
  font-weight: bold;
}

.editor_textItalic {
  font-style: italic;
}

.editor_textUnderline {
  text-decoration: underline;
}

.embedBlock {
  user-select: none;
}
.embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}

.word-break {
  word-break: break-all;
}
