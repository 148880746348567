@font-face {
  font-family: 'Gilmer';
  src: url('../../public/fonts/gilmer/GilmerRegular.ttf');
}

@font-face {
  font-family: 'Gilmer Light';
  src: url('../../public/fonts/gilmer/GilmerLight.ttf');
}

@font-face {
  font-family: 'Gilmer Bold';
  src: url('../../public/fonts/gilmer/GilmerBold.ttf');
}

@font-face {
  font-family: 'Avenir Next Regular';
  src: url('../../public/fonts/avenir-next/regular.ttf');
}
